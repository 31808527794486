import Analytics from '../analytics';
export var BUTTON_BLOCKER_EVENT = 'button_blocker';
export var MODAL_BLOCKER_EVENT = 'modal_blocker';
export var HOME_VIEW_EVENT = 'home_view';
export var trackHomeview = function (eventName, text, event) {
    Analytics.trackGTM(eventName, {
        event_data: {
            action: 'click',
            area: 'home_view',
            text: text
        }
    });
    if (event) {
        event.preventDefault();
        var anchorTarget = event.target;
        window.location.href = anchorTarget.href;
    }
};
export var trackButtonClick = function (area, text) {
    return Analytics.trackGTM('button', {
        event_data: {
            action: 'click',
            area: area,
            text: text
        }
    });
};
var RegularFunnelsEvents = /** @class */ (function () {
    function RegularFunnelsEvents() {
    }
    RegularFunnelsEvents.funnelStart = function (name, step) {
        return Analytics.trackGTM('funnel_start', {
            event_data: { funnel_name: name, funnel_step: step }
        });
    };
    RegularFunnelsEvents.funnelProgress = function (name, step) {
        return Analytics.trackGTM('funnel_progress', {
            event_data: { funnel_name: name, funnel_step: step }
        });
    };
    RegularFunnelsEvents.funnelCompleted = function (name, step) {
        return Analytics.trackGTM('funnel_completed', {
            event_data: { funnel_name: name, funnel_step: step }
        });
    };
    RegularFunnelsEvents.HOME_CREATION_FUNNEL = {
        name: 'home_creation_regular',
        step: {
            listing: 'home_creation_type_of_listing',
            address: 'home_creation_address',
            surroundingTags: 'home_creation_surrounding_tags',
            size: 'home_creation_size',
            beds: 'home_creation_beds',
            amenities: 'home_creation_amenities',
            description: 'home_creation_description',
            calendar: 'home_creation_calendar',
            photos: 'home_creation_photos',
            registration: 'home_creation_registration_number',
            final: 'home_creation_sumup_finalscreen'
        }
    };
    RegularFunnelsEvents.FILLING_PANNEL = {
        name: 'filling_pannel_regular',
        step: {
            createMyHome: 'filling_pannel_create_my_home',
            surroundingTags: 'filling_pannel_surrounding_tags',
            beds: 'filling_pannel_beds',
            size: 'filling_pannel_square_meter',
            bathrooms: 'filling_pannel_bathroom',
            photos: 'filling_pannel_photos',
            amenities: 'filling_pannel_amentities',
            description: 'filling_pannel_home_description',
            neigborhood: 'filling_pannel_neigborhood_description',
            calendar: 'filling_pannel_calendar',
            publishedHome: ' filling_pannel_published_home'
        }
    };
    return RegularFunnelsEvents;
}());
export { RegularFunnelsEvents };
export var trackVerificationModalView = function () {
    var eventData = {
        area: 'homeview',
        action: 'view',
        text: 'verification_collection',
        collection: true
    };
    Analytics.trackGTM('modal', { event_data: eventData });
};
export var trackVerificationButtonClick = function () {
    var eventData = {
        area: 'homeview',
        action: 'click',
        text: 'complete_verification',
        collection: true
    };
    Analytics.trackGTM('button', { event_data: eventData });
};
