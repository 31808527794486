import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from '../../../i18n';
import Utils from '../../../utils/utils';

import { fetchUser } from '../../../actions/user';
import { fetchHomes } from '../../../actions/homes';
import { fetchTopMenuNotifications } from '../../../actions/notifications';

import Home from '../../../models/Home';
import User from '../../../models/User';
import Notification from '../../../models/Notification';

import Loading from '../../common/Loading';
import MenuProfileUser from './MenuProfileUser';
import MenuProfileHome from './MenuProfileHome';
import Analytics from '../../../utils/analytics';

export class MenuProfile extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User),
        homes: PropTypes.arrayOf(PropTypes.instanceOf(Home)),
        fetchUser: PropTypes.func.isRequired,
        fetchHomes: PropTypes.func.isRequired,
        fetchTopMenuNotifications: PropTypes.func.isRequired,
        topMenuNotifications: PropTypes.instanceOf(Notification)
    };

    constructor(props) {
        super(props);
        this.state = {
            menuStyle: {}
        };
        this.onResize = _.debounce(this.onResize.bind(this), 50);
        this.update = this.update.bind(this);
        this.needPreferredDestinationsCleanUp = this.needPreferredDestinationsCleanUp.bind(this);
        this.shouldDisplayListingWithLhsLogo = this.shouldDisplayListingWithLhsLogo.bind(this);
    }

    componentDidMount() {
        this.updateTopMenuBadge();
        this.resize(this.state);
        window.addEventListener('resize', this.onResize);
        // this should be useless with use of Redux everywhere
        document.addEventListener('refresh_top_menu_profile', this.update);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        this.resize(nextState);
    }

    componentDidUpdate() {
        this.updateTopMenuBadge();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        document.removeEventListener('refresh_top_menu_profile', this.update);
    }

    onResize() {
        this.resize(this.state);
    }

    resize(state) {
        if (this.menu && $('#head-nav-band').length > 0) {
            const maxHeight = window.innerHeight - $('#head-nav-band').outerHeight();
            if (maxHeight !== state.menuStyle.maxHeight) {
                this.setState({
                    menuStyle: {
                        maxHeight,
                        overflowY: 'auto'
                    }
                });
            }
        }
    }

    isSubscriptionExpired() {
        if (
            !this.props.topMenuNotifications ||
            !this.props.topMenuNotifications.get('profile') ||
            !this.props.topMenuNotifications.get('profile').my_plan
        ) {
            return false;
        }

        return (
            this.props.topMenuNotifications.get('profile').my_plan.reason == Notification.SUBSCRIPTION_EXPIRED
        );
    }

    update() {
        this.props.fetchUser();
        this.props.fetchHomes();
        this.props.fetchTopMenuNotifications();
    }

    getTrackingData(event) {
        event.preventDefault();
        const path = window.location.pathname;
        const firstTwoElements = path.split('/').slice(1, 3);

        const eventHandleFavorites = {
            action: 'click',
            text: 'header_favorites_homes',
            area: firstTwoElements.join(' ')
        };

        Analytics.trackGTM('HandleFavorites', { event_data: eventHandleFavorites });
        window.location.href = i18n.t('user:favorites.list.url');
    }

    updateTopMenuBadge() {
        // don't update menu badge count if not rendered yet
        // or if some data are missing
        if (!this.menu || !this.props.user || !this.props.topMenuNotifications) {
            return;
        }
        const badgeNb = this.props.topMenuNotifications.get('profile')
            ? this.menu.querySelectorAll('.top-menu-messages').length >
              this.props.topMenuNotifications.get('profile').nb_notification
                ? this.menu.querySelectorAll('.top-menu-messages').length
                : this.props.topMenuNotifications.get('profile').nb_notification
            : this.menu.querySelectorAll('.top-menu-messages').length;
        const badgeProfil = document.getElementById('badge-profil');
        badgeProfil.setAttribute('data-value', badgeNb);
        if (badgeNb == 0) {
            badgeProfil.style.display = 'none';
        } else {
            badgeProfil.style.display = 'block';
            badgeProfil.innerHTML = badgeNb;
        }
    }

    // display clean preferred destinations notification
    needPreferredDestinationsCleanUp() {
        const notifications = this.props.topMenuNotifications;
        if (!notifications) {
            return false;
        }
        const profile = notifications.get('profile');
        return profile && profile.my_preferred_destinations
            ? profile.my_preferred_destinations.reason
            : false;
    }

    displayTopMenuMessages(message) {
        return (
            <div className="top-menu-messages">
                <p className="primary-strong">{message}</p>
            </div>
        );
    }

    shouldDisplayListingWithLhsLogo() {
        const { homes, user } = this.props;

        if (!homes || homes.length === 0) {
            return false;
        }

        const userPlatforms = user?.get('user_platform');

        const heListing = homes.find((home) => !home.get('is_from_lhs'));

        return heListing && Utils.userIsFromHEAndLHS(userPlatforms);
    }

    render() {
        const { user, homes } = this.props;
        const needCleanUp = this.needPreferredDestinationsCleanUp();
        const noHomeCreated = !homes || homes.length === 0;
        if (!user) {
            // return false;
            return (
                <ul
                    ref={(el) => {
                        this.menu = el;
                    }}
                    className="dropdown-menu scrollable-menu"
                    role="menu"
                    id="profil_elt"
                    style={this.state.menuStyle}
                >
                    <li>
                        <Loading />
                    </li>
                    <li>
                        <a className="link-top-profile" href={i18n.t('url:logout.url')}>
                            <i className="icon-button-power"></i>
                            <span className="link-title">{i18n.t('common:logout')}</span>
                        </a>
                    </li>
                </ul>
            );
        }
        return (
            <ul
                ref={(el) => {
                    this.menu = el;
                }}
                className="dropdown-menu scrollable-menu"
                role="menu"
                id="profil_elt"
                style={this.state.menuStyle}
            >
                <MenuProfileUser user={user} notifications={this.props.topMenuNotifications} />

                {(homes || []).map(
                    (home) =>
                        home.id && (
                            <MenuProfileHome
                                key={home.id}
                                home={home}
                                user={user}
                                single={homes.length <= 1}
                                notifications={this.props.topMenuNotifications}
                                shouldDisplayListingWithLhsLogo={this.shouldDisplayListingWithLhsLogo()}
                            />
                        )
                )}

                <li>
                    <a
                        className="link-top-profile"
                        href={noHomeCreated ? i18n.t('url:signup_step_1') : i18n.t('home:home.create.url')}
                    >
                        <i className="icon-header-profile-add-home"></i>
                        <span className="link-title">{i18n.t('home:create_a_home')}</span>
                        {(!homes || homes.length === 0) &&
                            this.displayTopMenuMessages(i18n.t('home:status-no-home'))}
                    </a>
                </li>

                <li>
                    <a className="link-top-profile" href={i18n.t('url:myexchanges')}>
                        <i className="icon-keys"></i>
                        <span className="link-title">{i18n.t('home:my_exchanges')}</span>
                    </a>
                </li>

                <li>
                    <a
                        className="link-top-profile"
                        href={`${i18n.t('user:user.edit.url', {
                            id: user.id
                        })}#${Utils.slugify(i18n.t('user:preferred.destinations'))}`}
                    >
                        <i className="icon-preferred-destinations"></i>
                        <span className="link-title">{i18n.t('user:preferred.destinations.menuitem')}</span>
                        {Boolean(needCleanUp) && (
                            <>
                                <span className="pull-right badge badge-menu">1</span>
                                {this.displayTopMenuMessages(i18n.t(`user:${needCleanUp}`))}
                            </>
                        )}
                    </a>
                </li>

                <li>
                    <a
                        className="link-top-profile"
                        href={i18n.t('user:favorites.list.url')}
                        onClick={this.getTrackingData}
                    >
                        <i className="icon-heart"></i>
                        <span className="link-title">{i18n.t('user:favorites.menuitem')}</span>
                    </a>
                </li>

                <li>
                    <a className="link-top-profile" href={i18n.t('user:theylovemyhome.list.url')}>
                        <i className="icon-header-profile-they-love-my-home"></i>
                        <span className="link-title">{i18n.t('dashboard:they_love_my_home_title')}</span>
                    </a>
                </li>

                <li className="my-plan-link">
                    <a className="link-top-profile" href={i18n.t('url:my-plan.url')}>
                        <i className="icon-credit-card"></i>
                        <span className="link-title">
                            {user && user.get('is_he_collection')
                                ? i18n.t('collection:menu-profile.collection.membership')
                                : i18n.t('subscription:my_subscription_plan')}
                        </span>
                        {this.isSubscriptionExpired() &&
                            this.displayTopMenuMessages(i18n.t('user:optimal.expired'))}
                        {user.isAutoRenew() &&
                            this.props.topMenuNotifications.isDefaultPaymentMethodExpired() &&
                            this.displayTopMenuMessages(
                                i18n.t('subscription:my-plan.payment_method_expired')
                            )}
                        {!this.isSubscriptionExpired() &&
                            !user.isSubscriber() &&
                            this.displayTopMenuMessages(i18n.t('user:activate-unlimited'))}
                    </a>
                </li>

                <li>
                    <a className="link-top-profile" href={i18n.t('common:reward.url.5')}>
                        <i className="icon-megaphone"></i>
                        <span className="link-title">{i18n.t('user:reward.label.5')}</span>
                    </a>
                </li>

                <li>
                    <a className="link-top-profile" href={i18n.t('url:settings.url', { id: user.id })}>
                        <i className="icon-header-profile-settings"></i>
                        <span className="link-title">{i18n.t('user:settings')}</span>
                    </a>
                </li>

                <li>
                    <a
                        className="link-top-profile"
                        href={`${i18n.t('url:logout.url')}${
                            user && user.get('is_he_collection') ? '-collection' : ''
                        }`}
                    >
                        <i className="icon-button-power"></i>
                        <span className="link-title">{i18n.t('common:logout')}</span>
                    </a>
                </li>
            </ul>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    homes: state.homes.length > 0 ? state.homes : null,
    topMenuNotifications: state.topMenuNotifications
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: bindActionCreators(fetchUser, dispatch),
    fetchHomes: bindActionCreators(fetchHomes, dispatch),
    fetchTopMenuNotifications: bindActionCreators(fetchTopMenuNotifications, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuProfile);
