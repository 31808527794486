import PropTypes from 'prop-types';
import React, { createRef, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { fetchSavedSearches } from '../../../src/actions/savedSearches';
import ApiSearch from '../../api/Search';
import i18n from '../../i18n';

import QueryField from './QueryField';
import Home from '../../models/Home';
import AutocompleteService from '../../utils/AutocompleteService';
import Utils from '../../utils/utils';

import Filters from '../../models/Filters';
import { setDisplayAlertsComponentStatus } from '../../actions/search';
import Screen from '../../utils/Screen';

import Api from '../../api/Api';
import InputSearch from './InputSearch';

const CustomGuestNb = React.lazy(() => import('./filters/CustomGuestNb'));

export const SearchBarContext = {
    HEADER: 'HEADER',
    DASHBOARD: 'DASHBOARD',
    HOMEPAGE: 'HOMEPAGE'
};

export class SearchBar extends React.PureComponent {
    // remi's comment : https://guesttoguest.atlassian.net/browse/DAT-47?focusedCommentId=41297
    static propTypes = {
        userId: PropTypes.number,
        // eslint-disable-next-line react/no-unused-prop-types
        queryString: PropTypes.string,
        filters: PropTypes.shape({
            location: PropTypes.shape({
                bounds: PropTypes.object
            }),
            calendar: PropTypes.shape({
                last_minute: PropTypes.bool,
                date_ranges: PropTypes.arrayOf(
                    PropTypes.shape({
                        from: PropTypes.string,
                        to: PropTypes.string
                    })
                ),
                flexibility: PropTypes.oneOf([1, 3, 7]),
                exchange_types: PropTypes.arrayOf(
                    PropTypes.oneOf(['available', 'guest-wanted', 'reciprocal'])
                ),
                duration: PropTypes.shape(
                    PropTypes.oneOf([
                        PropTypes.shape({
                            kind: PropTypes.oneOf(['week-end', 'one-week', 'two-weeks', 'one-month'])
                        }),
                        PropTypes.shape({ number_of_nights: PropTypes.number })
                    ])
                )
            }),
            availability: PropTypes.shape({
                date_range: PropTypes.shape({
                    from: PropTypes.string,
                    to: PropTypes.string,
                    flexibility: PropTypes.number
                }),
                reciprocal: PropTypes.bool
            }),
            home: PropTypes.shape({
                size: PropTypes.shape({
                    beds: PropTypes.shape({
                        adults: PropTypes.number,
                        children: PropTypes.number,
                        babies: PropTypes.number
                    })
                })
            })
        }),
        searchProcessing: PropTypes.func,
        updateDisplayAlertsStatus: PropTypes.func,
        fetchSavedSearches: PropTypes.func,
        home: PropTypes.instanceOf(Home),
        context: PropTypes.string,
        className: PropTypes.string,
        showFull: PropTypes.bool
    };

    static defaultProps = {
        filters: {
            calendar: {},
            location: {},
            placeId: null,
            home: {
                size: {
                    beds: {}
                }
            }
        },
        context: SearchBarContext.HEADER,
        showFull: false
    };

    state = {
        filters: []
    };

    constructor(props) {
        super(props);
        this.state = this.getStateFromProps(props);
        this.setLocation = this.setLocation.bind(this);
        this.setPeriod = this.setPeriod.bind(this);
        this.setCalendar = this.setCalendar.bind(this);
        this.handleClickQueryField = this.handleClickQueryField.bind(this);
        this.handleOpenSuggestion = this.handleOpenSuggestion.bind(this);
        this.setSuggestion = this.setSuggestion.bind(this);
        this.closeSuggestion = this.closeSuggestion.bind(this);
        this.setQueryString = this.setQueryString.bind(this);
        this.setGuestsNb = this.setGuestsNb.bind(this);
        this.openGuestNb = this.openGuestNb.bind(this);
        this.onCalendarClose = this.onCalendarClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.clearLocation = this.clearLocation.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.setShowCalendarModal = this.setShowCalendarModal.bind(this);
        this.setOpenedModals = this.setOpenedModals.bind(this);
        this.guestNb = createRef();
        this.openFromSimulateClick = createRef();
        this.divRef = createRef();
        this.refNumberOfGuest = createRef();
    }

    async componentDidMount() {
        // get last search
        if (
            this.guestNb &&
            !this.state.home &&
            this.props.userId &&
            this.props.context === SearchBarContext.HEADER
        ) {
            ApiSearch.getLocalLastSearch(this.props.userId).then((lastSearch) => {
                if (lastSearch && lastSearch.query && lastSearch.query.home) {
                    this.setState({
                        home: { ...lastSearch.query.home }
                    });
                }
            });
        }

        document.addEventListener('clear-search-location', this.clearLocation);
        document.addEventListener('click', this.handleOpenSuggestion);
        this.props.fetchSavedSearches();

        // get last researches
        if (this.props.userId) {
            await this.getLastResearches();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!(JSON.stringify(nextProps.home) !== JSON.stringify(this.props.home))) {
            this.setState(() => {
                const state = this.getStateFromProps(nextProps);
                if (_.has(state, 'location') && _.has(state.location, 'user')) {
                    delete state.location.user;
                }
                return state;
            });
        }
    }

    async getLastResearches() {
        try {
            const lastResearches = _.uniq(await Api.Search.getLastResearches(), (value) => value.place);
            const lastThreeSearches = lastResearches.slice(0, 3);
            if (lastThreeSearches.length > 0) {
                lastThreeSearches.forEach((research) => (research.filter = new Filters(research.query)));
                this.setState({ lastResearches: lastThreeSearches });
            }
        } catch (error) {
            console.error('error:', error);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !(JSON.stringify(prevProps.home) !== JSON.stringify(this.props.home)) &&
            !this.state.searchFieldFocus &&
            !(this.state.searchFieldFocus !== prevState.searchFieldFocus)
        ) {
            if (!this.isSearchPage()) {
                if (this.checkFieldsFilled()) {
                    this.submit();
                    return;
                }
            }

            if (this.props.context === SearchBarContext.HEADER) {
                // Only submit filters if home, location or period changed
                const prevFilters = _.pick(JSON.parse(JSON.stringify(prevState)), [
                    'home',
                    'location',
                    'calendar'
                ]);
                const newFilters = _.pick(JSON.parse(JSON.stringify(this.state)), [
                    'home',
                    'location',
                    'calendar'
                ]);

                const wasEmptyState = _.compact(_.values(prevFilters)).length === 0;
                if (!_.isEqual(newFilters, prevFilters) && !this.displaySearchIcon()) {
                    this.submit(wasEmptyState);
                }
            }
        }
        // get last researches
        if (prevProps.userId !== this.props.userId) {
            await this.getLastResearches();
        }
    }

    displaySearchIcon() {
        return Boolean(document.location.pathname.match(/search-v2/i)) === false || !this.props.userId;
    }

    checkFieldsFilled() {
        const { context, userId } = this.props;

        if (
            (userId && Screen.isTabletOrUpper()) ||
            (userId && !Screen.isTabletOrUpper() && context !== SearchBarContext.HEADER)
        ) {
            return (
                this.state.location &&
                this.checkFieldsOfCalendar() &&
                this.state.home &&
                (this.state.home.size.beds.adults ||
                    this.state.home.size.beds.children ||
                    this.state.home.size.beds.babies)
            );
        } else {
            return !_.isEmpty(this.state.location);
        }
    }

    checkFieldsOfCalendar() {
        const calendarTree = { ...this.state.calendar };
        // flexible
        if (
            calendarTree.date_ranges?.length &&
            calendarTree.duration &&
            (calendarTree.duration.kind || calendarTree.duration.number_of_nights)
        ) {
            return true;
        }
        // fixed
        if (
            calendarTree.date_ranges?.length === 1 &&
            calendarTree.date_ranges[0].from &&
            calendarTree.date_ranges[0].to
        ) {
            return true;
        }
        // last minute
        return false;
    }

    componentWillUnmount() {
        document.removeEventListener('clear-search-location', this.clearLocation);
        document.removeEventListener('click', this.handleOpenSuggestion);
    }

    getStateFromProps(props) {
        let newCalendar = {};
        if (props.filters.calendar) {
            newCalendar = props.filters.calendar;
        } else if (this.state && this.state.calendar) {
            newCalendar = this.state.calendar;
        }
        return {
            queryString: props.queryString || '',
            location: props.filters.location || false,
            calendar: newCalendar,
            placeId: props.filters.placeId || false,
            openSuggestion: this?.state?.openSuggestion ? this.state.openSuggestion : false,
            searchFieldFocus: this.keepFocus()
                ? this?.state?.searchFieldFocus
                    ? this.state.searchFieldFocus
                    : false
                : false,
            queryStringLength: 0,
            openGuestNb: false,
            home: props.filters.home || (this?.state?.home ? this.state.home : false),
            startDate: null,
            endDate: null,
            showCalendarModal: this?.state?.showCalendarModal ? this.state.showCalendarModal : false,
            openedModals: this?.state?.openedModals ? this.state.openedModals : false
        };
    }

    keepFocus() {
        return this.props.context === SearchBarContext.DASHBOARD && Screen.isMobile();
    }

    setLocation(prediction) {
        if (this.searchField) this.searchField.blur();

        if (prediction.gid) {
            this.setState({
                queryString: prediction.label,
                location: {
                    polygon: {
                        location_id: prediction.gid,
                        provider: 'Jawg'
                    },
                    country:
                        prediction.countryCode && prediction.countryCode.length === 2
                            ? prediction.countryCode
                            : null
                }
            });

            if (!this.state.calendar.date_ranges && !this.isSearchPage()) {
                this.setShowCalendarModal(true);
            }
        } else {
            this.setState({
                queryString: null,
                location: false,
                placeId: null
            });
        }
    }

    handleClickQueryField(event) {
        if (Screen.isMobile()) {
            const isSearchBarClicked = Utils.isSearchBarClicked(event);
            const queryInputSearch = document.querySelector(
                '.search-block-container .form-inline .search-menu .span-search-menu #btn-search-mobile'
            );
            if (queryInputSearch !== null && isSearchBarClicked) queryInputSearch.click();
            if (
                !this.state.openedModals &&
                ((!isSearchBarClicked && queryInputSearch) ||
                    (isSearchBarClicked && queryInputSearch === null))
            ) {
                Utils.openModalsFullScreen(isSearchBarClicked);
                this.setState({ openSuggestion: true, openedModals: true });
            }
        } else {
            this.setState({ openSuggestion: !(event.target.value?.length > 0) });
        }
    }

    handleOpenSuggestion(event) {
        event.stopPropagation();
        const queryField = this.searchField?.input.current.refs.input.contains(event.target);
        const searchMenu = event.target.closest('.opened-modals');

        if (Screen.isMobile() && searchMenu) {
            this.setState({ openSuggestion: true });
        } else if (!queryField) {
            this.setState({ openSuggestion: false });
        }

        this.setState({
            searchFieldFocus: queryField
        });
    }

    setSuggestion(queryString) {
        AutocompleteService.getCountrySuggestions(queryString).then((suggestions) => {
            if (suggestions instanceof Home) {
                return document.location.assign(
                    i18n.t('home:home.view.url', {
                        id: queryString
                    })
                );
            }
            let selectedSuggestion = suggestions[0];

            Utils.selectSuggestion(suggestions[0], this.setLocation);

            // WITHIN MY COUNTRY: dirty fix to solve the pbm of 'ile de france' selected first instead of 'France'
            if (queryString === 'France' || queryString === 'Espagne') {
                selectedSuggestion = this.findSuggestion(suggestions, queryString);
            }
            Utils.selectSuggestion(selectedSuggestion, this.setLocation);
        });

        if (Screen.isMobile()) {
            this.closeSuggestion();
        }

        this.setState({
            queryString,
            openSuggestion: !this.state.openSuggestion
        });
    }

    findSuggestion(suggestions, queryString) {
        return suggestions.find(({ vicinity, title }) => vicinity === queryString && title === queryString);
    }

    setQueryString(queryString) {
        this.setState({
            openSuggestion: Screen.isMobile(),
            queryStringLength: queryString.length
        });
    }

    setPeriod(period) {
        if (period.length < 2) {
            this.setState((state) => ({
                availability: { ...state.availability, date_range: false }
            }));
            return;
        }
        const newAvailability = {
            ...this.state.availability,
            date_range: {
                from: moment.isMoment(period[0]) ? period[0].format('YYYY-MM-DD') : null,
                to: moment.isMoment(period[1]) ? period[1].format('YYYY-MM-DD') : null
            }
        };

        if (!_.isEqual(this.state.availability.date_range, newAvailability.date_range)) {
            this.setState({
                availability: newAvailability
            });
            if (
                this.state.home &&
                this.state.home.size.beds.adults === null &&
                this.state.home.size.beds.children === null &&
                this.state.home.size.beds.babies === null
            ) {
                this.setState({ home: false });
            }
        }
    }

    setShowCalendarModal(show) {
        if (show) {
            Utils.openModalsFullScreen();
        }
        this.setState({ showCalendarModal: show });
    }

    setCalendar(calendarTree) {
        const newCalendar = { ...calendarTree };
        if (!_.isEqual(this.state.calendar, newCalendar)) {
            this.setState({ calendar: newCalendar });
            Utils.closeModalsFullScreen();
        }
        if (this.state.location?.polygon?.location_id && !this.isSearchPage()) {
            this.openGuestNb(true);
        }
    }

    setGuestsNb(adults, children, babies) {
        this.setState({
            home: {
                ...this.state.home,
                size: {
                    beds: {
                        adults: adults ? adults : null,
                        children: children ? children : null,
                        babies: babies ? babies : null
                    }
                }
            }
        });

        if (Screen.isMobile()) {
            Utils.closeModalsFullScreen();
        }
    }

    openGuestNb(open) {
        const openGuestNb = Boolean(open);
        if (openGuestNb) {
            Utils.openModalsFullScreen();
        } else {
            Utils.closeModalsFullScreen();
        }
        this.setState({
            openGuestNb
        });
    }

    submit(wasEmptyState = false) {
        const { queryString, home } = this.state;
        const filters = Object.assign({}, this.props.filters, {
            location: this.state.location || null,
            calendar: JSON.parse(JSON.stringify(this.state.calendar)),
            home: { ...home },
            placeId: this.state.placeId
        });

        // redirect or update url
        if (!this.isSearchPage()) {
            // build search url
            const params = new Filters(filters).params();
            let url = `${i18n.t('search:search_url', {
                slug: queryString ? encodeURIComponent(queryString) : 'everywhere'
            })}`;
            url = url.replace(/\/$/, '');
            const paramString = $.param(params);
            if (paramString.length > 0) {
                url += `?${paramString}&context=search-bar`;
            }
            // navigate to search page
            document.location.assign(url);
            return;
        }

        if (wasEmptyState) {
            // The first search on the search page should be triggered by another component
            return;
        }

        // Go back to page 1
        const event = new CustomEvent('update_page', {
            detail: {
                page: 1
            }
        });
        document.dispatchEvent(event);

        this.props.searchProcessing({
            queryString,
            filters,
            placeId: this.state.placeId
        });
    }

    isSearchPage() {
        const baseSearchUrl = i18n.t('search:search_url').replace(/\/$/, '');
        return Boolean(document.location.href.match(baseSearchUrl));
    }

    onCalendarClose() {
        Utils.closeModalsFullScreen();
    }

    goToEveryWhereLocation() {
        if (Screen.isMobile()) {
            this.closeSuggestion();
        }
        // TODO: On search page we should to handle this differrently
        document.location.assign(i18n.t('search:search_url', { slug: 'everywhere' }));
    }

    closeSuggestion() {
        Utils.closeModalsFullScreen();
        this.setState({ openSuggestion: false, openedModals: false, searchFieldFocus: false });
    }

    // Task: Extract this method into a functional component for improved maintainability
    suggestions(translatedCountry) {
        const { lastResearches, openedModals } = this.state;

        const recentSearch = Screen.isMobile()
            ? i18n.t('search:search.recent_search').toUpperCase()
            : i18n.t('search:search.recent_search');

        const searchSuggestions = (
            <div className="container-suggestions">
                {Screen.isMobile() && (
                    <p className="suggestions">{i18n.t('search:search.suggestions').toUpperCase()}</p>
                )}
                {translatedCountry && (
                    <>
                        {Screen.isMobile() && <span className="separator-suggestion" />}
                        <div
                            className="container-suggestion"
                            onClick={() => this.setSuggestion(translatedCountry)}
                        >
                            <i className="icon-suggestion container-suggestion-icon" />
                            <div className="suggestion">
                                <p className="suggestion-first-text">
                                    {i18n.t('home:within_my_country')} {translatedCountry}
                                </p>
                                <p className="suggestion-second-text">{i18n.t('home:local_travel')}</p>
                            </div>
                        </div>
                    </>
                )}
                <span className="separator-suggestion" />
                <div className="container-suggestion" onClick={() => this.goToEveryWhereLocation()}>
                    <i className="icon-anywhere-in-the-world container-suggestion-icon" />
                    <div className="suggestion">
                        <p className="suggestion-first-text">
                            {i18n.t('home:suggestion.anywhere-world.title')}
                        </p>
                        <p className="suggestion-second-text">
                            {i18n.t('home:suggestion.anywhere-world.text')}
                        </p>
                    </div>
                </div>
                {lastResearches?.length > 0 && (
                    <div className="recent-search">
                        <p className="recent-search-text">{recentSearch}</p>
                        {lastResearches.map((research) => {
                            const filtersStr = research.filter.stringify().filter(Boolean).join(' | ');
                            const searchUrl = `${i18n.t('search:search_url', {
                                slug: encodeURIComponent(research?.place)
                            })}?${$.param(research.filter.params())}`;
                            return (
                                <a key={research.id} href={searchUrl}>
                                    <span className="separator-suggestion" />
                                    <div className="container-suggestion">
                                        <i className="icon-location-small container-suggestion-icon" />
                                        <div className="suggestion">
                                            <p className="suggestion-first-text">{research.place}</p>
                                            <p className="suggestion-second-text">{filtersStr}</p>
                                        </div>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                )}
            </div>
        );

        if (Screen.isMobile() && this.props.context === SearchBarContext.HEADER) {
            if (openedModals) {
                setTimeout(() => {
                    const fullScreenSearch = document.querySelector('.opened');
                    return fullScreenSearch && createPortal(searchSuggestions, fullScreenSearch);
                }, 100);
            } else {
                const searchFilter = document.getElementById('head-nav-band');
                return searchFilter && createPortal(searchSuggestions, searchFilter);
            }
        }

        return searchSuggestions;
    }

    onSubmit(e) {
        e.preventDefault();
        this.submit();
    }

    clearLocation() {
        this.setState(
            {
                queryString: ''
            },
            () => {
                this.searchField.input.current.focus();
            }
        );
    }

    setOpenedModals(openedModals) {
        this.setState({ openedModals });
    }

    render() {
        const { userId, showFull, context, home, updateDisplayAlertsStatus, className } = this.props;

        const calendarTree = this.state.calendar ? JSON.parse(JSON.stringify(this.state.calendar)) : null;

        let translatedCountry = null;

        if (home?.get('translated_admins')?.country) {
            translatedCountry = home.get('translated_admins').country;
        }

        return (
            <form
                method="GET"
                action="#"
                className={`form-inline navbar-form navbar-left navbar-form-mobile search-bar-form ${className}`}
                role="search"
                onSubmit={this.onSubmit}
            >
                <div
                    className={classNames('search-menu input-group', {
                        'flexible-search-flag': true
                    })}
                >
                    <span
                        className={classNames('span-search-menu', {
                            'opened-modals': Screen.isMobile() && this.state.openedModals
                        })}
                    >
                        {Screen.isMobile() && (
                            <>
                                <i className="icon-shape-close" onClick={this.closeSuggestion} />
                                <h2>{i18n.t('search:input-search-mobile.title')}</h2>
                            </>
                        )}
                        {this.state.openedModals || !Screen.isMobile() ? (
                            <QueryField
                                isFlexibleSearch={true}
                                className="search-field"
                                placeholder={i18n.t('search:searchfield_placeholder')}
                                value={this.state.queryString || ''}
                                ref={(node) => {
                                    this.searchField = node;
                                }}
                                onSelect={this.setLocation}
                                onChange={this.setQueryString}
                                updateDisplayAlertsStatus={updateDisplayAlertsStatus}
                                setOpenedModals={this.setOpenedModals}
                                closeSuggestion={this.closeSuggestion}
                                {...(!this.state.openSuggestion && {
                                    onClick: (event) =>
                                        this.setState({ openSuggestion: !(event.target.value.length > 0) })
                                })}
                            />
                        ) : (
                            <div
                                id="btn-search-mobile"
                                className="form-group btn-open-modal-search"
                                onClick={this.handleClickQueryField}
                            >
                                {this.state.queryString || i18n.t('search:searchfield_placeholder')}
                            </div>
                        )}
                        {(this.state.openSuggestion || this.state.openedModals) &&
                            this.suggestions(translatedCountry)}
                    </span>
                    {(showFull || userId) && (
                        <>
                            <div
                                className={classNames('form-group', {
                                    'hidden-xs': context === SearchBarContext.HEADER,
                                    'hidden-sm': context === SearchBarContext.HEADER
                                })}
                            >
                                <InputSearch
                                    mode="range"
                                    nbMonths={Screen.isMobile() ? 1 : 2}
                                    classname={['search-calendar']}
                                    onClose={this.onCalendarClose}
                                    setCalendar={this.setCalendar}
                                    showModal={this.state.showCalendarModal}
                                    setShowModal={this.setShowCalendarModal}
                                    calendar={calendarTree}
                                    context={context}
                                />

                                {context === SearchBarContext.DASHBOARD && (
                                    <Suspense fallback={<div>{`${i18n.t('common:loading')}...`}</div>}>
                                        <CustomGuestNb
                                            className="hidden-sm hidden-md hidden-lg"
                                            customGuestNbRef={this.guestNb}
                                            divRef={this.divRef}
                                            popupRef={this.refNumberOfGuest}
                                            onChange={this.setGuestsNb}
                                            open={this.state.openGuestNb}
                                            openGuestNb={this.openGuestNb}
                                            openFromSimulateClick={this.openFromSimulateClick}
                                            home={this.state.home}
                                            hashPreferredDestination
                                            showTitle={true}
                                        />
                                    </Suspense>
                                )}
                            </div>
                            {(context === SearchBarContext.HEADER || !Screen.isMobile()) && (
                                <div className="form-group hidden-xs" ref={this.divRef}>
                                    <Suspense fallback={<div>{`${i18n.t('common:loading')}...`}</div>}>
                                        <CustomGuestNb
                                            customGuestNbRef={this.guestNb}
                                            divRef={this.divRef}
                                            popupRef={this.refNumberOfGuest}
                                            onChange={this.setGuestsNb}
                                            open={this.state.openGuestNb}
                                            openGuestNb={this.openGuestNb}
                                            openFromSimulateClick={this.openFromSimulateClick}
                                            home={this.state.home}
                                            hashPreferredDestination
                                            showTitle={false}
                                        />
                                    </Suspense>
                                </div>
                            )}
                        </>
                    )}
                    {this.displaySearchIcon() && (
                        <button
                            type="submit"
                            className={classNames('btn btn-default', {
                                'dashboard-search-button':
                                    context === SearchBarContext.DASHBOARD ||
                                    context === SearchBarContext.HOMEPAGE,
                                'hidden-xs': context === SearchBarContext.HEADER,
                                'hidden-sm': context === SearchBarContext.HEADER
                            })}
                            ref={(btn) => {
                                this.submitBtn = btn;
                            }}
                        >
                            <i className="icon-search-bold" />
                            <span
                                className={classNames('search-button-text', {
                                    'sr-only': context === SearchBarContext.HEADER
                                })}
                            >
                                {i18n.t('search:submit')}
                            </span>
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        userId: state.user ? state.user.id : null,
        queryString: state.search.main.queryString,
        filters: state.search.main.filters,
        resultsCount: state.search.main.resultsCount,
        home: state.homes ? state.homes[0] : null
    };
}

const mapDispatchToProps = (dispatch) => ({
    updateDisplayAlertsStatus: bindActionCreators(setDisplayAlertsComponentStatus, dispatch),
    fetchSavedSearches: bindActionCreators(fetchSavedSearches, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
