import dot from 'dot-object';
import queryParser from 'query-string-parser';
import i18n from '../i18n';
import Model from './Model';
import Feature from './Feature';
import Utils from '../utils/utils';

export default class Filters extends Model {
    static HEIGHT_SEARCH_FILTER_ROW = 54;

    static TYPE_FIXED_DATES = 'fixed-dates';
    static TYPE_FLEXIBLE_DATES = 'flexible-dates';
    static TYPE_LAST_MINUTE = 'last-minute';
    static SEARCH_TYPES = [Filters.TYPE_FIXED_DATES, Filters.TYPE_FLEXIBLE_DATES, Filters.TYPE_LAST_MINUTE];

    static DURATION_KINDS = ['week-end', 'one-week', 'two-weeks', 'one-month'];

    // boolean filters
    static IS_PRIMARY = {
        name: 'is_primary',
        primary: 'primary',
        secondary: 'secondary'
    };

    static IS_PRIVATE_ROOM = {
        name: 'is_private_room',
        private: 'private-room',
        whole: 'whole-unit'
    };

    static HOME_TYPE = {
        name: 'home_type',
        flat: 'flat',
        home: 'home'
    };

    constructor(attributes, excludes = ['bounds'], options = {}) {
        attributes = Object.assign({}, Filters.getEmptyFilters(), Utils.filterObjectRecursive(attributes));
        super(attributes, options);
        this.excludes = excludes;
    }

    static getEmptyFilters() {
        return {
            location: {},
            calendar: {
                date_ranges: [],
                flexibility: null,
                exchange_types: [],
                duration: {}
            },
            guestpoints: {},
            home: {
                amenities: [],
                surrounding: [],
                size: {
                    beds: {}
                },
                exclude_animals: []
            },
            host: {
                groups: []
            }
        };
    }

    static paramsToFilters = {
        guests_nb: 'guests_nb',
        home_capacity: 'guests_nb',
        bounds: 'location.bounds',
        location_id: 'location.polygon.location_id',
        'polygon.provider': 'location.polygon.provider',
        'polygon.location_id': 'location.polygon.location_id',
        provider: 'location.polygon.provider',
        // latlong: 'location.center',
        zoom: 'location.zoom',
        country: 'location.country',
        iso: 'location.country',
        guestpoints_from: 'guestpoints.from',
        guestpoints_to: 'guestpoints.to',
        home_type: 'home.type',
        is_private_room: 'home.is_private_room',
        exclude_animals: 'home.exclude_animals',
        eco_level: 'home.eco_level',
        is_primary: 'home.is_primary',
        bedrooms: 'home.size.bedrooms',
        bathrooms: 'home.size.bathrooms',
        user_filters: 'location.user',
        filter: 'filters',
        place_id: 'placeId',
        adults: 'home.size.beds.adults',
        children: 'home.size.beds.children',
        babies: 'home.size.beds.babies',
        preferred_destination: 'preferred_destination',
        collection: 'luxury',
        luxury: 'luxury'
    };

    static getParamsToFilters = () => {
        return {
            ...Filters.paramsToFilters,
            date_ranges: 'calendar.date_ranges',
            flexibility: 'calendar.flexibility',
            exchange_types: 'calendar.exchange_types',
            duration_kind: 'calendar.duration.kind',
            number_of_nights: 'calendar.duration.number_of_nights',
            last_minute: 'calendar.last_minute'
        };
    };

    static filtersToParams = {
        'location.bounds': 'bounds',
        'location.polygon.location_id': 'location_id',
        'location.polygon.provider': 'provider',
        'location.user': 'user_filters',
        guests_nb: 'guests_nb',
        country: 'iso',
        'location.country': 'iso',
        'guestpoints.from': 'guestpoints_from',
        'guestpoints.to': 'guestpoints_to',
        filters: 'filter',
        'home.type': 'home_type',
        'home.is_private_room': 'is_private_room',
        'home.exclude_animals': 'exclude_animals',
        'home.eco_level': 'eco_level',
        'home.is_primary': 'is_primary',
        'home.size.bedrooms': 'bedrooms',
        'home.size.bathrooms': 'bathrooms',
        'home.amenities': 'amenities',
        'host.groups': 'groups',
        placeId: 'place_id',
        'home.size.beds.adults': 'adults',
        'home.size.beds.children': 'children',
        'home.size.beds.babies': 'babies',
        preferred_destination: 'preferred_destination',
        luxury: 'luxury'
    };

    static getFiltersToParams = () => {
        return {
            ...Filters.filtersToParams,
            'calendar.flexibility': 'flexibility',
            'calendar.exchange_types': 'exchange_types',
            'calendar.duration.kind': 'duration_kind',
            'calendar.duration.number_of_nights': 'number_of_nights',
            'calendar.last_minute': 'last_minute'
        };
    };

    static filtersToFlat = {
        'guestpoints.from': 'guestpoints.from',
        'guestpoints.to': 'guestpoints.to',
        filters: 'filters',
        guests_nb: 'guests_nb',
        'home.type': 'home_type',
        'home.is_private_room': 'is_private_room',
        'home.exclude_animals': 'exclude_animals',
        'home.eco_level': 'eco_level',
        'home.is_primary': 'is_primary',
        'home.amenities': 'amenities',
        'home.surrounding': 'surrounding',
        'home.size.bedrooms': 'bedrooms',
        'home.size.bathrooms': 'bathrooms',
        'home.size.double_bed': 'double_bed',
        'home.size.single_bed': 'single_bed',
        'home.size.children_bed': 'children_bed',
        'home.size.baby_bed': 'baby_bed',
        'host.groups': 'groups',
        'location.user': 'user_filters',
        preferred_destination: 'preferred_destination',
        placeId: 'placeId',
        'home.size.beds.adults': 'home.size.beds.adults',
        'home.size.beds.children': 'home.size.beds.children',
        'home.size.beds.babies': 'home.size.beds.babies',
        'sort.type': 'sort',
        lastMinute: 'lastMinute',
        collection: 'collection',
        luxury: 'luxury'
    };

    static getFiltersToFlat = () => {
        return {
            ...Filters.filtersToFlat,
            'calendar.date_ranges': 'calendar.date_ranges',
            'calendar.duration': 'calendar.duration',
            'calendar.flexibility': 'calendar.flexibility',
            'calendar.exchange_types': 'calendar.exchange_types',
            'calendar.duration.number_of_nights': 'calendar.number_of_nights',
            'calendar.duration.kind': 'calendar.kind',
            'calendar.last_minute': 'calendar.last_minute'
        };
    };

    static whiteList = [
        'home-verified',
        'home-with-picture',
        'response-rate-above-threshold',
        'quick-response',
        'no-bed-up',
        'reverse-search'
    ];

    static getCalendarSearchType = (calendar) => {
        if (calendar.last_minute === true) {
            return Filters.TYPE_LAST_MINUTE;
        }
        if (
            calendar.date_ranges?.length > 0 &&
            (calendar.duration?.kind || calendar.duration?.number_of_nights)
        ) {
            return Filters.TYPE_FLEXIBLE_DATES;
        }
        if (
            calendar.date_ranges?.length === 1 &&
            calendar.date_ranges[0].from &&
            calendar.date_ranges[0].to
        ) {
            return Filters.TYPE_FIXED_DATES;
        }
        return false;
    };

    static fromUrl() {
        // get query parameters
        const params = queryParser.fromQuery(window.location.search);
        return Filters.fromParams(params);
    }

    static fromParams(params, preferredDestination = false) {
        if (Array.isArray(params)) {
            params = {};
        }
        let filters = Filters.getEmptyFilters();
        if (preferredDestination) {
            Filters.paramsToFilters['beds.adults'] = Filters.paramsToFilters.adults;
            Filters.paramsToFilters['beds.children'] = Filters.paramsToFilters.children;
            Filters.paramsToFilters['beds.babies'] = Filters.paramsToFilters.babies;
        }
        dot.transform(Filters.getParamsToFilters(), params, filters);
        const modifiers = {
            'location.bounds': Filters.formatBounds,
            'location.bounds.ne.lat': (val) => parseFloat(val),
            'location.bounds.ne.lon': (val) => parseFloat(val),
            'location.bounds.sw.lat': (val) => parseFloat(val),
            'location.bounds.sw.lon': (val) => parseFloat(val),
            'location.polygon.location_id': (value) => value,
            'location.polygon.provider': (value) => value,
            'location.center': Filters.formatCenter,
            'location.zoom': (value) => parseInt(value, 10),
            'calendar.date_ranges': (dateRanges) =>
                typeof dateRanges === 'string' && dateRanges.length > 0
                    ? dateRanges.split('--').map((dateRange) => {
                          const [from, to] = dateRange.split('_');
                          if (!to) {
                              return {
                                  from: moment(from).startOf('month').format('YYYY-MM-DD'),
                                  to: moment(from).endOf('month').format('YYYY-MM-DD')
                              };
                          }
                          return { from, to };
                      })
                    : [],
            'calendar.flexibility': (flexibility) => parseInt(flexibility, 10),
            'calendar.duration.kind': (kind) => kind,
            'calendar.duration.number_of_nights': (days) => parseInt(days, 10),
            'calendar.exchange_types': (types) =>
                typeof types === 'string' && types.length > 0 ? types.split(',') : [],
            // eslint-disable-next-line camelcase
            'calendar.last_minute': (last_minute) => String(last_minute) == 'true',
            'location.country': (value) => value,
            guests_nb: (value) => parseInt(value, 10),
            'home.size.beds.adults': (value) => parseInt(value, 10),
            'home.size.beds.children': (value) => parseInt(value, 10),
            'home.size.beds.babies': (value) => parseInt(value, 10),
            'guestpoints.from': (value) => parseInt(value, 10),
            'guestpoints.to': (value) => parseInt(value, 10),
            'home.type': (type) => type,
            'home.eco_level': (value) => parseInt(value, 10),
            'home.is_primary': (bool) => String(bool) == 'true',
            'home.size.bedrooms': (value) => parseInt(value, 10),
            'home.size.bathrooms': (value) => parseInt(value, 10),
            filters: Filters.convertOldFilter,
            placeId: (value) => value,
            lastMinute: (bool) => String(bool) == 'true',
            'home.is_private_room': (bool) => String(bool) == 'true',
            luxury: (bool) => String(bool) == 'true',
            'preferred_destination.bounds.ne.lat': (value) => parseFloat(value),
            'preferred_destination.bounds.ne.lon': (value) => parseFloat(value),
            'preferred_destination.bounds.sw.lat': (value) => parseFloat(value),
            'preferred_destination.bounds.sw.lon': (value) => parseFloat(value)
        };
        filters = dot.dot(filters);
        dot.override = true;
        dot.object(filters, modifiers);
        if (_.has(filters, 'filters')) {
            // prevent invalid data (legacy)
            if (!Array.isArray(filters.filters)) {
                filters.filters = [];
            }

            // distinguish from amenities and surrounding
            const surroundingNames = Feature.FEATURES.filter(
                (feature) => feature.category === Feature.SURROUNDING_TAGS
            ).map((feature) => feature.icon);

            // retrieve amenities
            const amenities = filters.filters.filter(
                (el) =>
                    !surroundingNames.includes(el) &&
                    !Filters.whiteList.includes(el) &&
                    !/^group-.*-(\d+)$/.exec(el)
            );
            dot.str('home.amenities', amenities, filters);

            // retrieve surrounding
            const surrounding = filters.filters.filter((el) => surroundingNames.includes(el));
            dot.str('home.surrounding', surrounding, filters);

            // retrieve groups
            const groups = [];
            filters.filters.forEach((name) => {
                const matches = /^group-.*-(\d+)$/.exec(name);
                if (matches && matches.length === 2) {
                    groups.push(parseInt(matches[1], 10));
                }
            });
            dot.str('host.groups', groups, filters);

            // preserve only valid filters
            filters.filters = _.filter(filters.filters, Filters.isValidFilter);
        }

        if (_.has(filters.location, 'polygon') && _.has(filters.location.polygon, 'location_id')) {
            delete filters.location.bounds;
        }

        return new Filters(filters);
    }

    static formatBounds(bounds) {
        if (_.isObject(bounds)) {
            bounds.ne = _.mapObject(bounds.ne, (val) => parseFloat(val));
            bounds.sw = _.mapObject(bounds.sw, (val) => parseFloat(val));
            return bounds;
        }
        const points = _.compact(bounds.split(',').map(parseFloat));
        if (points.length >= 4) {
            return {
                ne: {
                    lat: Number.isNaN(points[2]) ? null : points[2],
                    lon: Number.isNaN(points[3]) ? null : points[3]
                },
                sw: {
                    lat: Number.isNaN(points[0]) ? null : points[0],
                    lon: Number.isNaN(points[1]) ? null : points[1]
                }
            };
        }
        return null;
    }

    static formatCenter(center) {
        const latlng = _.compact(center.split(',').map(parseFloat));
        if (latlng.length === 2) {
            return {
                lat: parseFloat(latlng[0]),
                lon: parseFloat(latlng[1])
            };
        }
        return center;
    }

    static isValidFilter(name) {
        return _.contains(Filters.whiteList, name);
    }

    static convertOldFilter(filters) {
        let converted = [];

        filters.forEach((name) => {
            if (_.isString(name)) {
                name = name.toLowerCase().replace(' ', '-');
                converted.push(name);
            }
        });

        const _oldFiltersMap = {
            home_capacity: 'guests_nb',
            '100perch': 'response-rate-above-threshold',
            home_verified: 'home-verified',
            'no-auxiliary-bed': 'no-bed-up',
            pets: 'pets-welcome'
        };

        converted = converted.map((name) => {
            if (_.has(_oldFiltersMap, name)) {
                return _oldFiltersMap[name];
            } else {
                return name;
            }
        });

        return converted;
    }

    params() {
        const params = dot.transform(Filters.getFiltersToParams(), this.attributes);
        if (Filters.getCalendarSearchType(this.attributes.calendar) === Filters.TYPE_FLEXIBLE_DATES) {
            params.date_ranges = this.attributes.calendar?.date_ranges
                ?.map((dateRange) => dateRange.from.substring(0, 7))
                .join('--');
        } else {
            params.date_ranges = this.attributes.calendar?.date_ranges
                ?.map((dateRange) => `${dateRange.from}_${dateRange.to}`)
                .join('--');
        }
        if (this.attributes.calendar?.exchange_types) {
            params.exchange_types = this.attributes.calendar.exchange_types.join(',');
        }
        if (params.guests_nb) {
            Object.assign(params, { adults: params.guests_nb });
            delete params.guests_nb;
        }
        dot.override = true;
        dot.object(params, {
            bounds: (bounds) => [bounds.sw.lat, bounds.sw.lon, bounds.ne.lat, bounds.ne.lon].join(','),
            is_primary: (bool) => Boolean(bool) === true,
            is_private_room: (bool) => Boolean(bool) === true
        });

        params.filter = _.union(params.filter, params.amenities);
        delete params.amenities;

        params.filter = _.union(params.filter, params.surrounding);
        delete params.surrounding;

        params.groups.forEach((groupId) => {
            params.filter.push(`group--${groupId}`);
        });
        delete params.groups;

        return params;
    }

    flatify(source = null) {
        const filters = {
            preferred_destination: {},
            guestpoints: {},
            home: {
                size: {
                    beds: {}
                }
            },
            calendar: {}
        };

        dot.transform(Filters.getFiltersToFlat(), this.attributes, filters);

        // Manage boolean filters
        if (
            _.has(filters, Filters.IS_PRIMARY.name) &&
            (filters[Filters.IS_PRIMARY.name] ?? null) !== null &&
            source === 'Filters'
        ) {
            filters[Filters.IS_PRIMARY.name] = [
                filters[Filters.IS_PRIMARY.name] ? Filters.IS_PRIMARY.primary : Filters.IS_PRIMARY.secondary
            ];
        }

        if (
            _.has(filters, Filters.HOME_TYPE.name) &&
            (filters[Filters.HOME_TYPE.name] ?? null) !== null &&
            source === 'Filters'
        ) {
            filters[Filters.HOME_TYPE.name] = [filters[Filters.HOME_TYPE.name]];
        }

        if (
            _.has(filters, Filters.IS_PRIVATE_ROOM.name) &&
            (filters[Filters.IS_PRIVATE_ROOM.name] ?? null) !== null &&
            source === 'Filters'
        ) {
            filters[Filters.IS_PRIVATE_ROOM.name] = [
                filters[Filters.IS_PRIVATE_ROOM.name]
                    ? Filters.IS_PRIVATE_ROOM.private
                    : Filters.IS_PRIVATE_ROOM.whole
            ];
        }

        if (_.has(filters, 'reciprocal')) {
            filters.reciprocal = String(filters.reciprocal) == 'true';
        }

        if (_.has(filters, 'last_minute')) {
            filters.lastMinute = String(filters.lastMinute) == 'true';
        }

        if (_.has(filters, 'luxury')) {
            filters.luxury = String(filters.luxury) == 'true';
        }

        return filters;
    }

    static fromFlatFilters(props, excludes) {
        const filters = Filters.getEmptyFilters();
        dot.transform(_.invert(Filters.filtersToFlat), props, filters);
        // home type should be a single value, convert array to single string or to null if multiple values
        if (Array.isArray(filters.home.type) && _.size(filters.home.type) === 1) {
            filters.home.type = filters.home.type[0];
        }

        return new Filters(filters, excludes);
    }

    stringify(options = {}) {
        const parts = [];
        const filters = this.getFiltersList();
        filters.forEach((filter) => {
            if (Array.isArray(filter.value)) {
                filter.value.forEach((val) => {
                    const subfilter = {
                        parent: filter.name,
                        name: val,
                        value: val
                    };
                    parts.push(Filters.stringifyFilter(subfilter, options));
                });
            } else if (
                _.isObject(filter.value) &&
                ['guestpoints', 'availability', 'preferred_destination', 'home'].indexOf(filter.name) < 0
            ) {
                for (const item in filter.value) {
                    const subfilter = {
                        parent: filter.name,
                        name: item,
                        value: filter.value[item]
                    };
                    parts.push(Filters.stringifyFilter(subfilter, options));
                }
            } else {
                parts.push(Filters.stringifyFilter(filter, options));
            }
        });
        return parts;
    }

    getFiltersList() {
        const list = [];
        const flatFilters = this.flatify();
        for (const name in flatFilters) {
            const value = flatFilters[name];
            // exclude some filters
            if (
                _.contains(this.excludes, name) ||
                _.isUndefined(value) ||
                _.isNull(value) ||
                (_.isObject(value) && _.isEmpty(value))
            ) {
                continue;
            }
            list.push({
                name,
                value
            });
        }
        return list;
    }

    static stringifyDateRanges(dateRanges) {
        return dateRanges
            .map((dateRange) => {
                return this.rangeOrMonth(dateRange) || dateRange;
            })
            .map((dateRange, index, ranges) => {
                if (dateRange.month) {
                    if (
                        index < ranges.length - 1 &&
                        ranges[index + 1].year &&
                        ranges[index + 1].year === dateRange.year
                    ) {
                        return `${dateRange.month}`;
                    } else {
                        return `${dateRange.month} ${dateRange.year}`;
                    }
                }
                if (dateRange.from && dateRange.to) {
                    return `${i18n.t('common:from_to_date', {
                        from: this.formatFromWithoutYearIfSameAsTo(dateRange),
                        to: moment(dateRange.to).format('ll')
                    })}`;
                }
                return '';
            })
            .filter((range) => range)
            .join(', ');
    }

    static stringifyFilter(filter, options = {}) {
        // format period
        if (filter.name == 'availability') {
            const availability = filter.value;
            if (availability.date_range && availability.date_range.flexibility) {
                return `${i18n.t('common:from_to_date', {
                    from: moment(availability.date_range.from).format('ll'),
                    to: moment(availability.date_range.to).format('ll')
                })} (+/- ${i18n.t(`common:duration.day`, {
                    count: availability.date_range.flexibility
                })})`;
            }
            return i18n.t('common:from_to_date', {
                from: moment(availability.date_range.from).format('ll'),
                to: moment(availability.date_range.to).format('ll')
            });
        }

        // date rages
        if (filter.name == 'date_ranges') {
            return this.stringifyDateRanges(filter.value);
        }

        if (filter.name == 'home_type') {
            if (['home', 'flat'].includes(filter.value)) {
                return i18n.t(`search:filter.${filter.value}`);
            }
            return '';
        }

        if (filter.name == 'flexibility') {
            if (filter.value) {
                return `±${i18n.t(`common:duration.day`, {
                    count: filter.value
                })}`;
            }
            return '';
        }

        if (filter.name === 'exchange_types') {
            var result = [];
            if (filter.value.includes('reciprocal')) {
                result.push(i18n.t(`search:filter.reciprocal`));
            } else if (filter.value.includes('guest-wanted')) {
                result.push(i18n.t(`search:filter.non_reciprocal`));
            } else if (filter.name === 'available') {
                result.push(i18n.t(`search:filter.exchange_type_any`));
            }
            return result.join(', ');
        }

        // format string for GP range
        if (filter.name == 'guestpoints') {
            const range = filter.value;
            if (_.isUndefined(range.from)) {
                if (_.isUndefined(range.to)) {
                    return;
                } else {
                    return i18n.t('search:less_than', {
                        to: range.to
                    });
                }
            } else if (_.isUndefined(range.to)) {
                return i18n.t('search:from_gp', {
                    from: range.from
                });
            } else {
                return i18n.t('search:from_to', {
                    from: range.from,
                    to: range.to
                });
            }
        }
        // format string for preferred_destination
        if (filter.name === 'preferred_destination') {
            return i18n.t(`search:filter.reverse-search`);
        }

        // à modifier en "search:filter.last-minute"
        if (filter.name === 'lastMinute') {
            return i18n.t('search:filter.last-minute-title');
        }

        if (filter.name === 'eco_level') {
            return i18n.t('home:feature-only-sustainable-homes');
        }

        if (filter.name === 'is_private_room') {
            return i18n.t('home:shared-type.private-room');
        }

        if (filter.parent === 'exclude_animals') {
            const foundFeature = Feature.FEATURES.find((feature) => feature.name === filter.name);
            return i18n.t(`home:${foundFeature.label}`);
        }

        // format string for multiple value filters
        if (filter.parent === 'amenities') {
            const foundFeature = Feature.FEATURES.find((feature) => feature.icon === filter.name);
            if (foundFeature) {
                return i18n.t(`home:${foundFeature.featureName}`);
            } else if (filter.name === 'high-speed-connexion') {
                return i18n.t('home:feature-remote-high-speed-connection');
            } else if (filter.name === 'dedicated-workspace') {
                return i18n.t('home:feature-remote-dedicated-work-space');
            } else if (filter.name === 'secured-pool') {
                return i18n.t('home:feature-kids-secured-swimming-pool');
            } else {
                return i18n.t(`search:${filter.name}`);
            }
        } else if (filter.parent === 'surrounding') {
            return i18n.t(`home:feature-surrounding-${filter.name}`);
        } else if (filter.parent === 'groups') {
            const group = (options.groups || []).find((grp) => grp.id == filter.value);
            if (group) {
                return i18n.t(`search:${group.label()}`);
            }
        }
        // format string for adults/children/babies
        if (filter.name === 'home' && filter.parent !== Filters.HOME_TYPE.name) {
            const home = filter.value;
            const result = [];
            const beds = home.size && home.size.beds ? Object.keys(home.size.beds) : [];
            beds.forEach(
                (bed) =>
                    home.size.beds[bed] > 0 &&
                    result.push(
                        i18n.t(`search:filter.${bed}_count`, {
                            count: home.size.beds[bed]
                        })
                    )
            );
            return result.join(', ');
        }
        // format string for quantified filter guests_nb (retrocompatibility)
        else if (filter.name === 'guests_nb') {
            return i18n.t(`search:filter.adults_count`, { count: filter.value });
        }
        // format string for quantified filters
        else if (
            _.contains(
                ['travelers', 'bedrooms', 'bathrooms', 'beds', 'double_bed', 'children_bed', 'baby_bed'],
                filter.name
            )
        ) {
            return i18n.t(`search:filter.${filter.name}_count`, { count: filter.value });
        }
        // this will be removed when we implement exchange types dialog
        else if (filter.name === 'reciprocal') {
            return i18n.t(`search:filter.reciprocal`);
        } else if (filter.name === 'guest-wanted') {
            return i18n.t(`search:filter.non_reciprocal`);
        } else if (filter.name === 'available') {
            return i18n.t(`search:filter.exchange_type_any`);
        } else if (filter.name === 'number_of_nights' && filter.value === 1) {
            return i18n.t(`search:filter.last-minute-title`);
        } else if (filter.name === 'kind') {
            if (filter.value === 'week-end') return i18n.t('common:duration.weekend');
            if (filter.value === 'one-week') return i18n.t('common:duration.week');
            if (filter.value === 'two-weeks') return i18n.t('common:duration.week_plural', { count: 2 });
            return i18n.t('common:duration.month');
        } else if (filter.name == '_duration') {
            // the duration is doubled by kind, deacitivate before checking on real rule
            if (filter.value.kind === 'week-end') {
                return i18n.t('common:duration.week-end');
            } else if (filter.value.kind === 'one-week') {
                return i18n.t('common:duration.one-week');
            } else if (filter.value.kind === 'two-weeks') {
                return i18n.t('common:duration.two-weeks');
            }
        }
        // format string for filter using their name as string
        else {
            result = i18n.t(`search:filter.${filter.name}`, { val: filter.value });
            return result === filter.name ? '' : result;
        }
    }

    // if some ranges are contiguous, merge them into one range
    static mergeDateRanges = (dateRanges) => {
        const ranges = [];
        dateRanges.forEach((dateRange) => {
            const lastRange = ranges[ranges.length - 1];
            if (lastRange && moment(lastRange.to).isSame(moment(dateRange.from).subtract(1, 'days'))) {
                lastRange.to = dateRange.to;
            } else {
                ranges.push(dateRange);
            }
        });
        return ranges;
    };

    // detect if dateRange.from and from.to are the beginning and the end of the month, and return the month if true
    static rangeOrMonth = (dateRange) => {
        const from = moment(dateRange.from);
        const to = moment(dateRange.to);

        // check if dateRange.to is the last day of the month
        if (to.date() === to.endOf('month').date() && from.date() === 1 && to.month() === from.month()) {
            return {
                month: from.format('MMMM'),
                year: from.format('YYYY')
            };
        }
        return false;
    };

    static formatFromWithoutYearIfSameAsTo = (dateRange) => {
        const from = moment(dateRange.from);
        const to = moment(dateRange.to);
        const string = from.format('ll');
        if (from.year() === to.year()) {
            return string.replace(from.format('YYYY'), '');
        }
        return string;
    };
}
